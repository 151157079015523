<template>
    <page-title
        title="応援カメラマン編集"
        icon="bi-people-fill"
    >
        <button-back
            :to="{name: 'PartnerList'}"
            text="応援カメラマン一覧へ"
        ></button-back>
    </page-title>

    <inline-loader v-if="loading"></inline-loader>
    <form v-else @submit.prevent="save">
        <section class="section">
            <div class="row align-items-end mb-3">
                <div class="form-group col-md-6">
                    <label>氏名</label>
                    <form-input
                        v-model="partner.photographer_name"
                        :required="true"
                    ></form-input>
                </div>
                <div class="form-group col-md-3">
                    <label>略称</label>
                    <form-input
                        v-model="partner.shortened_name"
                        :required="true"
                    ></form-input>
                </div>
                <div class="form-group col-md-4">
                    <label>ランク</label>
                    <form-select
                        v-model="partner.partner_rank"
                        :options="partner_ranks"
                        empty_option="-- 選択 --"
                        :required="true"
                    ></form-select>
                </div>
                <div class="form-group col-md-2">
                    <label>名刺</label>
                    <form-checkbox
                        v-model="partner.has_business_card"
                        option_label="有"
                    ></form-checkbox>
                </div>
                <div class="form-group col-md-2">
                    <label>誓約書</label>
                    <form-checkbox
                        v-model="partner.has_pledge"
                        option_label="済"
                    ></form-checkbox>
                </div>
            </div>
        </section>

        <section class="section">
            <div class="row mb-4">
                <div class="form-group col-md-4">
                    <label>郵便番号</label>
                    <form-input
                        v-model="partner.zip"
                    ></form-input>
                </div>
                <div class="form-group col-md-3">
                    <label>都道府県</label>
                    <form-select
                        v-model="partner.pref"
                        :options="prefs"
                        empty_option="-- 選択 --"
                    ></form-select>
                </div>
                <div class="form-group col-md-10">
                    <label>住所</label>
                    <form-input
                        v-model="partner.address"
                    ></form-input>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-md-6 mb-3">
                    <label>電話番号</label>
                    <form-input-tel
                        v-model="partner.tel"
                    ></form-input-tel>
                </div>
                <div class="form-group col-md-6 mb-3">
                    <label>メールアドレス</label>
                    <form-input-email
                        v-model="partner.email"
                    ></form-input-email>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-md-2">
                    <label>生まれ年</label>
                    <form-input-number
                        v-model="partner.birth_year"
                        min="1900"
                        step="1"
                    ></form-input-number>
                </div>
                <div class="form-group col-md-3 align-self-end">
                    <form-checkbox
                        v-model="partner.has_car"
                        option_label="車有り"
                    ></form-checkbox>
                </div>
                <div class="form-group col-md-4" v-if="parseInt(partner.has_car, 10) === HasCar.YES">
                    <label>車種（任意）</label>
                    <form-input
                        v-model="partner.car_type"
                    ></form-input>
                </div>
            </div>
        </section>

        <section class="section">
            <div class="col-md-10">
                <table class="table">
                    <thead class="">
                        <th class=""></th>
                        <th class="text-center">繁忙期入れるか</th>
                    </thead>
                    <tbody>
                        <tr>
                            <td>入学式</td>
                            <td>
                                <form-select
                                    v-model="partner.entrance_ceremony_status"
                                    :options="evaluations"
                                    empty_option="-"
                                ></form-select>
                            </td>
                        </tr>
                        <tr>
                            <td>運動会</td>
                            <td>
                                <form-select
                                    v-model="partner.sports_day_status"
                                    :options="evaluations"
                                    empty_option="-"
                                ></form-select>
                            </td>
                        </tr>
                    </tbody>
                    <tfoot></tfoot>
                </table>
            </div>
        </section>

        <section class="section">
            <div class="row">
                <div class="form-group col-md-10">
                    <label>採用の経緯</label>
                    <form-input
                        v-model="partner.details"
                    ></form-input>
                </div>
                <div class="form-group col-md-3">
                    <label>雰囲気・愛想</label>
                    <form-select
                        v-model="partner.pleasant_status"
                        :options="evaluations"
                        empty_option="-"
                    ></form-select>
                </div>
            </div>
        </section>

        <section class="section">
            <table class="table">
                <thead>
                    <tr>
                        <th class="col-md-9"></th>
                        <th class="text-center col-md-2">撮影技量</th>
                        <th class="text-center col-md-11">備考</th>
                    </tr>
                </thead>
                <tbody>
                    <template v-for="photographer_skill in sort_skills" :key="photographer_skill">
                        <tr>
                            <td>{{ photographer_skill.skill.skill_name }}</td>
                            <td>
                                <form-select
                                    v-model="photographer_skill.skill_level"
                                    :options="evaluations"
                                    empty_option="-"
                                ></form-select>
                            </td>
                            <td>
                                <form-input
                                    v-model="photographer_skill.note"
                                ></form-input>
                            </td>
                        </tr>
                    </template>

                </tbody>
                <tfoot></tfoot>
            </table>
        </section>

        <section class="section">
            <div class="row mb-3">
                <div class="form-group col-md-10 mb-3">
                    <label>特殊機材（ひな壇、ストロボセット等）</label>
                    <form-textarea
                        v-model="partner.special_equipment"
                    ></form-textarea>
                </div>
                <div class="form-group col-md-10 mb-3">
                    <label>苦手な機材（望遠ない等）</label>
                    <form-textarea
                        v-model="partner.weak_equipment"
                    ></form-textarea>
                </div>
            </div>
            <div class="row mb-3">
                <div class="form-group col-md-10 mb-3">
                    <label>備考（過去の失敗や特性等）</label>
                    <form-textarea
                        v-model="partner.personal_note"
                    ></form-textarea>
                </div>
                <div class="form-group col-md-10 mb-3">
                    <label>ランクアップ課題</label>
                    <form-textarea
                        v-model="partner.rank_up_problem"
                    ></form-textarea>
                </div>
            </div>
        </section>

        <section class="section">
            <table class="table">
                <thead>
                    <tr>
                        <th class="col-md-4">出禁の学校・イベント</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <template v-if="partner.banned_photographers.length">
                        <tr v-for="banned_photographer, index in partner.banned_photographers" :key="banned_photographer">
                            <td>{{ banned_photographer.school.school_name }}</td>
                            <td>
                                <button type="button" class="btn btn-outline-danger" @click="removeBannedSchool(index)">削除</button>
                            </td>
                        </tr>
                    </template>
                </tbody>
                <tfoot></tfoot>
            </table>
            <div class="row">
                <div class="col-md-5">
                    <form-select
                        v-model="selected_school_id"
                        :options="options_school"
                        empty_option="-- 選択 --"
                    ></form-select>
                </div>
                <div class="col-md-3">
                    <button type="button" class="btn btn-primary" @click="addBannedSchool">
                        <i class="bi bi-plus"></i>追加
                    </button>
                </div>
            </div>
        </section>

        <section class="section">
            <form-upload
                v-model="this.partner.file"
                :file_type="FileType.PARTNER"
            ></form-upload>
        </section>

        <section class="section">
            <div class="d-flex justify-content-between">
                <button-exec-update
                    size="btn-lg"
                ></button-exec-update>
                <button-exec-delete
                    size="btn-lg"
                    @click="$refs.confirm_remove.show()"
                ></button-exec-delete>
            </div>
        </section>
    </form>

    <confirm-dialog ref="confirm_remove" @ok="remove()">
        <p>削除してよろしいですか？</p>
    </confirm-dialog>

</template>

<script>
import PageTitle from '@/components/PageTitle.vue';
import ConfirmDialog from '@/components/tools/ConfirmDialog';
import InlineLoader from '@/components/tools/InlineLoader';
import Partner from '@/models/entities/partner';
import Skill from '@/models/entities/skill';
import PhotographerSkill from '@/models/entities/photographer-skill';
import School from '@/models/entities/school';
import BannedPhotographer from '@/models/entities/banned-photographer';
import PartnerRank from '@/models/enums/partner/partner-rank';
import HasCar from '@/models/enums/partner/has-car';
import Evaluation from '@/models/enums/partner/evaluation';
import Pref from '@/models/enums/pref';
import FileType from '@/models/enums/file-type';
import ButtonBack from '@/components/buttons/ButtonBack';
import ButtonExecUpdate from '@/components/buttons/ButtonExecUpdate';
import ButtonExecDelete from '@/components/buttons/ButtonExecDelete';
import FormInput from '@/components/forms/FormInput';
import FormInputEmail from '@/components/forms/FormInputEmail';
import FormInputNumber from '@/components/forms/FormInputNumber.vue';
import FormInputTel from '@/components/forms/FormInputTel.vue';
import FormCheckbox from '@/components/forms/FormCheckbox.vue';
import FormSelect from '@/components/forms/FormSelect.vue';
import FormTextarea from '@/components/forms/FormTextarea.vue';
import FormUpload from '@/components/forms/FormUpload.vue';

export default {
    name: 'PartnerEdit',
    components: {
        PageTitle,
        ConfirmDialog,
        InlineLoader,
        ButtonBack,
        ButtonExecUpdate,
        ButtonExecDelete,
        FormInput,
        FormInputEmail,
        FormInputNumber,
        FormInputTel,
        FormCheckbox,
        FormSelect,
        FormTextarea,
        FormUpload,
    },
    inject: [
        'startScreenLoading',
        'endScreenLoading',
        'showMessage'
    ],
    data() {
        return {
            loading: 0,
            partner_id: parseInt(this.$route.params.id, 10),
            partner: new Partner(),
            banned_photographer: new BannedPhotographer(),
            skills: [],
            schools: [],
            selected_school_id: null,
            partner_ranks: PartnerRank.options(),
            evaluations: Evaluation.options(),
            prefs: Pref.options(),
            options_school: [],
            HasCar: HasCar,
            FileType: FileType,
            sort_skills: [],
        }
    },
    mounted() {
        this.FetchPartner();
        this.FetchSchoolList();
    },
    computed: {
    },
    methods: {
        FetchPartner() {
            this.loading++;
            this.$http.get(`/partners/${this.partner_id}`)
            .then(response => {
                this.partner = new Partner(response.data);

                this.FetchSkillList();

            })
            .finally(() => {
                this.loading--;
            });
        },
        FetchSchoolList() {
            this.loading++;
            this.$http.get('/schools/all')
            .then(response => {
                for (let row of response.data) {
                    this.schools.push(new School(row));
                    this.options_school.push({key: row.school_id, label: row.school_name})
                }
            })
            .finally(() => {
                this.loading--;
            });
        },
        FetchSkillList() {
            this.loading++;
            this.$http.get('/skills')
            .then(response => {
                for (let row of response.data) {
                    let skill = new Skill(row);
                    this.skills.push(skill);
                    this.pickPartnerSkill(skill);
                }

                this.sortPartnerSkill();
            })
            .finally(() => {
                this.loading--;
            });
        },
        pickPartnerSkill(skill) {
            let index = this.partner.skills.findIndex(partner_skill => parseInt(partner_skill.skill.skill_id, 10) == parseInt(skill.skill_id, 10));

            if (index === -1) {
                let photographer_skill = new PhotographerSkill();
                photographer_skill.skill = skill;
                this.partner.skills.push(photographer_skill);
            }
        },
        sortPartnerSkill() {
            // すべてのパートナースキルがDBにあるわけではないので、
            // パートナースキルがpickPartnerSkillメソッドで詰められた後並び替えをしている。
            this.sort_skills = this.partner.skills.sort((a, b) => {
                return a.skill.priority < b.skill.priority ? -1 : 1;
            });
        },
        addBannedSchool() {
            if (this.selected_school_id !== null) {
                this.banned_photographer.school = this.schools.find(school => school.school_id === this.selected_school_id);
                this.partner.banned_photographers.push(this.banned_photographer)
                this.banned_photographer = new BannedPhotographer();
                this.selected_school_id = null;
            }
        },
        removeBannedSchool(index) {
            this.partner.banned_photographers.splice(index, 1);
        },
        save() {
            this.startScreenLoading();
            this.$http.put(`/partners/${this.partner_id}`, this.partner)
            .then(() => {
                this.showMessage('編集しました');
            })
            .finally(() => {
                this.endScreenLoading();
            });
        },
        remove() {
            this.startScreenLoading();
            this.$http.delete(`/partners/${this.partner_id}`)
            .then(() => {
                this.showMessage('削除しました');
                this.$router.push({name: 'PartnerList'});
            })
            .finally(() => {
                this.endScreenLoading();
            })
        }
    }
}
</script>

<style scoped>

</style>
